//cores
$branco: #fff;
$preto: #000;
$azul: #004EA5;
$azul-escuro: #1B3762;
$amarelo: #FFCC00;
$cinza: #A7A7A7;

//fonte
$antenna: "Antenna";
$bebas: "Bebas Neue";
$bebas-book: "Bebas Neue Book";
$bebas-bold: "Bebas Neue Bold";

//media queries
$mq-tablet: "(max-width: 1199px)";
$mq-mobile: "(max-width: 640px)";