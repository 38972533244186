@charset "UTF-8";
@import "variaveis";
@import "mixins";
@import "fonts";
@import "global";

/*-------------------------------
            HEADER
-------------------------------*/
@import "header";

/*-------------------------------
            INDEX
-------------------------------*/
@import "index";

/*-------------------------------
            FOOTER
-------------------------------*/
@import "footer";

/*-------------------------------
            ERROR
-------------------------------*/
@import "error";

/*seta topo*/
#img-return-top {
    display: none;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 100%;
    left: 0;
    margin: -70px 0 0 20px;
    cursor: pointer;
    z-index: 300;
}