/*------------------------------------*\
  #SLIDE
\*------------------------------------*/
.slideshow-index {
    border-bottom: 4px solid $amarelo;
    display: table;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    .cycle-slideshow { 
        padding: 0;
        position: relative;
        width: 100%;

        img { 
            display: block;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;

            @media #{$mq-mobile} {
                height: 267px;
                left: 50% !important;
                margin-left: -532px;
                width: 1050px;
            }
        }
    }

    /* pager */
    .cycle-pager { 
        height: 30px;
        left: 50%;
        line-height: 30px;
        margin-left: -600px;
        overflow: hidden;
        position: absolute;
        text-align: right;
        top: 93%;
        width: 1110px;
        z-index: 500; 

        @media #{$mq-tablet} {
            margin-left: -480px;
            top: 80%;
            width: 960px;
        }

        @media #{$mq-mobile} {
            margin-left: -340px;
            width: 640px;
        }

        span { 
            color: $cinza;
            cursor: pointer;
            display: inline-block;
            font-family: arial;
            font-size: 40px;
            padding: 0 3px;

            @media #{$mq-mobile} {
                font-size: 90px;
            }

            &.cycle-pager-active {
                color: $amarelo;
            }
        }
    }
}

.jetinho-contato{
    background-color: $amarelo;
    position: relative;
    z-index: 999;

    .conteudo{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .jetinho-bloco{
        width: 140px;
        position: relative;

        img{
            position: absolute;
            top: -106px;
        }
    }

    h2{
        font-size: 27px;
        margin: 0 7px;
    }

    a{
        font-size: 30px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin: 0 7px;
        font-weight: bolder;

        img{
            width: 27px;
            height: 27px;
            margin-right: 5px;
        }
    }
}

/*------------------------------------*\
  #A EMPRESA
\*------------------------------------*/
.empresa {
    display: table;
    padding: 50px 0 160px;
    width: 100%;

    article {
        margin-top: 20px;

        p {
            font-family: $antenna;
            font-size: 15px;
            line-height: 18px;
            text-align: justify;

            @media #{$mq-mobile} {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }
}

    .empresa_robo {
        margin-top: 220px;
        position: relative;

        @media #{$mq-mobile} {
            margin-top: 150px;
        }

        &__img {
            height: 398px;
            left: 0;
            position: absolute;
            top: -110px;
            width: 502px;

            @media #{$mq-tablet} {
                top: -92px;
            }
        }

        &__retangulo {
            background-color: $azul;
            border-radius: 80px;
            float: right;
            height: 215px;
            padding: 40px 55px;
            text-align: right;
            width: 78%;
            @include _flex(flex-end, center);

            @media #{$mq-tablet} {
                height: 270px;
            }

            @media #{$mq-mobile} {
                height: auto;
                margin-top: 220px;
                padding-right: 30px;
                padding-left: 30px;
                text-align: left;
                width: 100%;

                justify-content: flex-start;
            }

            p {
                color: $branco;
                font-family: $bebas-bold;
                font-size: 25px;
                letter-spacing: 1px;
                text-transform: uppercase;
                width: 84%;

                @media #{$mq-tablet} {
                    width: 71%;
                }

                @media #{$mq-mobile} {
                    font-size: 30px;
                    margin-top: 65px;
                    width: 100%;
                }
            }
        }
    }

/*------------------------------------*\
  #SERVIÇOS
\*------------------------------------*/
.servicos {
    display: table;
    padding-top: 50px;
    width: 100%;

    @media #{$mq-tablet} {
        padding-bottom: 100px;
    }

    @media #{$mq-mobile} {
        padding-top: 50px;
        padding-bottom: 350px;
    }

    h2 {
        color: $branco;
        font-family: $bebas-bold;
        font-size: 28px;
        letter-spacing: 4px;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media #{$mq-mobile} {
            font-size: 36px;
        }
    }
}

.bgParallax {
    background-attachment: fixed;
    background-image: url(../images/servicos_bg.jpg);
    background-position: 50% 0;
    background-repeat: repeat;
    float: left;
    min-height: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
}
    .servicos__side {

        width: 65%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media #{$mq-tablet} {
            width: 530px;
        }

        @media #{$mq-mobile} {
            width: 600px;
        }
    }

    .servicos__side_list {
        float: left;
        width: 350px;
        margin-bottom: 50px;

        @media #{$mq-mobile} {
            border-width: 4px;
            width: 285px;
        }

        &:first-of-type {
            // margin-right: 55px;

            @media #{$mq-tablet} {
                margin-right: 0;
                margin-bottom: 30px;
            }

            @media #{$mq-mobile} {
                margin-right: 30px;
            }
        }

        h3{
            color: white;
            font-size: 23px;
            font-weight: bold;

            &:before {
                background-image: url(/app/assets/images/icons/ponto-amarelo.png);
                content: "";
                width: 12px;
                height: 12px;
                position: relative;
                margin-right: 7px;
                background-size: cover;
            }
        }

        ul{
            margin-left: 25px;
        }

        li {
            color: $branco;
            font-family: $antenna;
            font-size: 14px;
            line-height: 15px;
            margin-bottom: 3px;
            text-transform: uppercase;

            @media #{$mq-mobile} {
                font-size: 20px;
                line-height: 20px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .servicos__img {
        height: 500px;
        position: absolute;
        right: 0;
        top: 100px;
        width: 500px;

        @media #{$mq-mobile} {
            top: 720px;
        }
    }





/*------------------------------------*\
  #PRODUTOS
\*------------------------------------*/

.produtos{

    display: table;
    width: 100%;
    padding-top: 170px;

    
    @media #{$mq-mobile} {
        padding-top: 230px;
    }

    .conteudo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    h2 {
        color: $azul;
        font-family: "Bebas Neue Book";
        font-size: 28px;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 40px;

        @media #{$mq-mobile} {
            font-size: 36px;
        }
    }

    .produtos-um{
       background-color: $azul; 
       height: 195px;

       h3{
           color: $amarelo;
           font-size: 25px;
       }

       p{
           font-size: 20px;
           color: $branco;

           @media #{$mq-mobile} {
                font-size: 25px;
            }
       }

       img{
            width: 53%;
            @media #{$mq-mobile} {
                width: 93%;
            }
        }
    }

    .produtos-dois{
       background-color: $amarelo; 
       height: 195px;

       h3{
            color: $azul;
            font-size: 25px;
        }

        img{

            @media #{$mq-mobile} {
                width: 100%;
            }
        }

        p{
            font-size: 20px;
            color: $preto;
            
           @media #{$mq-mobile} {
                font-size: 25px;
            }
        }
    }

    .produtos-tres{
       @extend .produtos-um;

       img{
            width: 47%;

            @media #{$mq-mobile} {
                width: 87%;
            }
        }
    }

        .produto-info, .produto-img{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .produto-info{

            div{
                flex-direction: column;
                justify-content: flex-start;
            }
        }
}





/*------------------------------------*\
  #PARCEIROS
\*------------------------------------*/
.parceiros {
    display: table;
    padding: 160px 0 160px;
    width: 100%;

    @media #{$mq-mobile} {
        padding-top: 100px;
    }

    article {
        margin-top: 20px;

        p {
            font-family: $antenna;
            font-size: 15px;
            line-height: 18px;
            text-transform: uppercase;
            width: 515px;

            @media #{$mq-mobile} {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }

    .empresa_robo {
        margin-top: 120px;
    }

    .empresa_robo__retangulo p {
        width: 75%;

        @media #{$mq-mobile} {
            width: 100%;
        }
    }
}

    .parceiros__list {
        margin-top: 80px;
        @include _flex(space-between, center, row, wrap);

        li {
            padding-bottom: 100px;
            width: 25%;
            @include _flex(center, center);

            @media #{$mq-tablet} {
                width: auto;
            }
        }
    }

