* {
    border: none;
    font-family: $bebas-book, Arial, Verdana, sans-serif;
    font-size: 100%;
    font-weight: normal;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body, main, header, footer {
    width: 100%;
}

main {
    display: table;
}

.conteudo {
    float: initial;
    height: 100%;
    margin: 0 auto;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
    width: 1200px;

    @media #{$mq-tablet} {
        width: 960px;
    }

    @media #{$mq-mobile} {
        padding-left: 20px;
        padding-right: 20px;
        width: 640px;
    }
}

body {
    background-color: $branco;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $bebas-book;
    margin: 0;
}

p {
    color: $preto;
    margin-bottom: 0;
    text-rendering: auto !important;
}

*:before,
*::before,
*:after,
*::after {
    clear: both;
    display: inline-block;
    position: absolute;
}

.lazy {
    display: none;
}

a {
    color: inherit;
}

a:focus,
a:hover {
    color: inherit;
}

a:link,
a:visited,
a:active {
    text-decoration: none;
}

*:focus {
    outline: none;
}

.escondido {
    display: none;
}

ul {
    margin-left: 0;
    margin-bottom: 0;
}

li {
    list-style: none;
}

ul li ul {
    margin-left: 0;
}

.left {
    float: left;
}

.right {
    float: right;
}

.desktop {
    @media #{$mq-mobile} {
        display: none;
    }
}

.mobile {
    display: none;

    @media #{$mq-mobile} {
        display: block;
    }
}

.titulo {
    color: $azul;
    font-family: $bebas-book;
    font-size: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;

    @media #{$mq-mobile} {
        font-size: 36px;
    }
}
