header{
    @media #{$mq-tablet} {
        display: table;
    }
}

.topo {
    background-color: $azul-escuro;
    height: 52px;
    position: relative;
    width: 100%;
    z-index: 9999;

    @media #{$mq-mobile} {
        height: 90px;
    }

    .conteudo {
        @include _flex(space-between, center);
    }
}

    /**
     * Logo
     */
    .logo {
        display: block;
        height: 28px;
        width: 195px;

        @media #{$mq-mobile} {
            display: none;
        }

        &.logo-mobile {
            display: none;

            @media #{$mq-mobile} {
                display: block;
                height: 45px;
                width: 304px;
            }
        }  
    }

    
    /**
     * Menu
     */
    menu {
        width: 45%;

        @media #{$mq-tablet} {
            width: 65%;
        }

        @media #{$mq-mobile} {
            display: none;
        }

        ul {
            width: 100%;
            @include _flex(space-between, center);
        }

        li {
            font-size: 20px;
            letter-spacing: 3px;
            text-transform: uppercase;

            @media #{$mq-tablet} {
                margin-right: 20px;
            }

            @media #{$mq-mobile} {
                font-size: 16px;
                margin-right: auto;
            }

            &:last-of-type {
                margin-right: 0;
            }

            a {
                color: $branco;
                font-family: $bebas-book;                

                &:hover,
                &:active,
                &:link {
                    color: $amarelo;
                }
            }
        }
    }


/*menu mobile*/
.dl-menuwrapper {
    display: none;

    @media #{$mq-mobile} {
        display: block;
    }
}

@media #{$mq-mobile} {
    .dl-menuwrapper {
        width: 80px;
        z-index: 501;
    }
    .dl-menuwrapper button {
        background: transparent;
        height: 50px;
        margin: 0 0 0 -5px;
        width: 67px;
    }
    .dl-menuwrapper button:after {
        background: $branco;
        box-shadow: 0 20px 0 $branco, 0 40px 0 $branco;
        height: 10px;
        top: 0;
        width: 100%;
    }
    .dl-menuwrapper button:hover,
    .dl-menuwrapper button.dl-active {
        background-color: transparent;
    }
    .dl-menuwrapper ul {
        background-color: $azul-escuro;
        border-top: 5px solid $branco;
    }
    .dl-menuwrapper .dl-menu {
        margin: 20px 0 0 -20px;
        position: absolute;
        width: 640px;
    }
    .dl-menuwrapper .dl-menu li {
        border-bottom: 1px solid $branco;
    }
    .dl-menuwrapper .dl-menu li a {
        color: $branco;
        font-size: 32px;
        font-weight: normal;
        letter-spacing: 3px;
        line-height: 35px;
    }
}