@font-face {
	font-family: 'Antenna';
	src: url('/app/assets/fonts/antenna-regular/Antenna-Regular.eot');
	src: url('/app/assets/fonts/antenna-regular/Antenna-Regular.eot?#iefix') format('embedded-opentype'),
		url('/app/assets/fonts/antenna-regular/Antenna-Regular.woff') format('woff'),
		url('/app/assets/fonts/antenna-regular/Antenna-Regular.ttf') format('truetype'),
		url('/app/assets/fonts/antenna-regular/Antenna-Regular.svg#Antenna-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Regular';
    src: url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.eot');
    src: url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.woff2') format('woff2'),
         url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.woff') format('woff'),
         url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.ttf') format('truetype'),
         url('/app/assets/fonts/bebas-neue-regular/bebasneue_regular-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.eot');
    src: url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.eot?#iefix') format('embedded-opentype'),
         url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.woff2') format('woff2'),
         url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.woff') format('woff'),
         url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.ttf') format('truetype'),
         url('/app/assets/fonts/bebas-neue-book/bebasneue_book-webfont.svg#bebas_neuebook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Bold';
    src: url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.eot');
    src: url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.woff2') format('woff2'),
         url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.woff') format('woff'),
         url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.ttf') format('truetype'),
         url('/app/assets/fonts/bebas-neue-bold/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
    font-weight: normal;
    font-style: normal;
}









