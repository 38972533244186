/*------------------------------------*\
  # FACEBOOK
\*------------------------------------*/
.facebook .conteudo {
    @include _flex(flex-end, flex-end);
}

    .facebook__retangulo {
        background-color: $amarelo;
        border-radius: 30px 30px 0 0;
        height: 41px;
        position: relative;
        width: 236px;

        span {
            color: $azul;
            font-family: $bebas-bold;
            font-size: 37px;
            line-height: 41px;
            margin-left: 45px;
            text-transform: uppercase;
        }

        a {
            display: block;
            position: absolute;
            right: 20px;
            top: -22px;

            img:hover {
                opacity: 0.9;
            }
        }
    }

/*------------------------------------*\
  #RODAPE AZUL
\*------------------------------------*/
.rodape {
    background: $azul;
    display: table;
    padding: 20px 0;
    position: relative;
    width: 100%;

    .conteudo {
        @include _flex(space-between, flex-start);

        @media #{$mq-mobile} {
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    aside {
        width: 50%;

        @media #{$mq-tablet} {
            width: 100%;
        }
    }
}

    .rodape_contato {
        
        h2 {
            color: $amarelo;
            font-family: $bebas-book;
            font-size: 28px;
            text-transform: uppercase;

            @media #{$mq-mobile} {
                font-size: 36px;
            }
        }
    }


        .rodape_contato__form {
            margin-top: 20px;
            width: 470px;

            @media #{$mq-mobile} {
                width: 600px;
            }

            ul li {
                margin-bottom: 30px;
                @include _flex(space-between, center);
            }

            label {
                color: $branco;
                font-family: $antenna;
                font-size: 14px;
                letter-spacing: 2px;
                text-transform: uppercase;

                @media #{$mq-mobile} {
                    font-size: 20px;
                }
            }

            li:nth-of-type(5) label {
                align-self: flex-start;
            }

            input,
            textarea {
                background-color: transparent;
                border: 2px solid $amarelo;
                color: $branco;
                font-family: $antenna;
                font-size: 14px;
                height: 35px;
                padding-left: 10px;
                text-transform: uppercase;
                width: 330px;
            
                @media #{$mq-mobile} {
                    font-size: 22px;
                    height: 55px;
                    width: 430px;
                }
            }

            textarea {
                height: 110px;
                resize: none;
            }

            .g-recaptcha {
                float: right;
                margin-top: -10px;
            }

            .bt-enviar {
                background-color: $amarelo;
                border: 0;
                color: $azul;
                cursor: pointer;
                float: right;
                font-family: $antenna;
                font-size: 14px;
                letter-spacing: 2px;
                margin-top: 15px;
                margin-bottom: 0;
                text-transform: uppercase;
            
                @media #{$mq-mobile} {
                    height: 60px;
                    font-size: 26px;
                    margin-top: 20px;
                    width: 100%;
                }
            
                &:hover {
                    color: $branco;
                }
            }
        }

    .rodape_mapa {
        margin-top: 51px;
        @include _flex(space-between, flex-end, column);
    }

        #map_canvas {
            float: right;
            height: 200px;
            width: 450px;

            @media #{$mq-tablet} {
                width: 350px;
            }

            @media #{$mq-mobile} {
                height: 300px;
                width: 600px;
            }
        }

        address {
            font-family: $bebas-book;
            font-size: 20px;
            font-style: normal;
            letter-spacing: 2px;
            margin-top: 20px;
            text-align: right;

            @media #{$mq-mobile} {
                font-size: 28px;
            }

            p {
               color: $branco; 
            }
        }

        .rodape_mapa__telefones {
            margin-top: 10px;
            text-align: right;

            li {
                line-height: 28px;
            }

            span {
                color: $amarelo;
                font-family: $bebas-bold;
                font-size: 28px;
                letter-spacing: 2px;
                text-transform: uppercase;

                @media #{$mq-mobile} {
                    font-size: 36px;
                }
            }

            a {
                color: $branco;
                font-family: $bebas-bold;
                font-size: 40px;

                @media #{$mq-mobile} {
                    font-size: 50px;
                }
            }
        }


/*------------------------------------*\
  #RODAPE CINZA
\*------------------------------------*/
.second-rodape {
    background: $cinza;
    display: table;
    height: 90px;
    width: 100%;

    .conteudo {
        @include _flex(center, center);
    }
}

.sistema {
   @include _flex(center, center);
}

.sidec {
    background-image: url(/app/assets/images/logo-sidec-labs.png);
    height: 30px;
    margin-right: 30px;
    width: 122px;

    @media #{$mq-mobile} {
        background-image: url(/app/assets/images/logo-sidec-labs-m.png);
        height: 45px;
        width: 180px;
    }
}

.dzaine {
    background-image: url(/app/assets/images/logo-dzaine.png);
    height: 19px;
    width: 80px;

    @media #{$mq-mobile} {
        background-image: url(/app/assets/images/logo-dzaine-m.png);
        height: 37px;
        width: 152px;
    }
}