@mixin position-absolute ($top: null, $right: null, $bottom: null, $left: null) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin _flex($content, $align, $direction: row, $wrap: nowrap){
	display: flex;
	align-items: $align;
	justify-content: $content;
	flex-direction: $direction;
	flex-wrap: $wrap;
}

@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
	transition-property: $property;
	transition-duration: $duration;
	transition-timing-function: $timing;
	transition-delay: $delay;
}

@mixin _placeholder_color($cor: red){
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $cor;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: $cor;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: $cor;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: $cor;
	}
}


/*------------------------------------*\
  #INDEX
\*------------------------------------*/

/**
 * Header
 */
 
/*---------------#LANÇAMENTOS----------------*/