.page-404 {
    height: 100%;
    width: 100%;
}

    .error_robo {
        display: table;
        margin: 180px 0 50px;
        position: relative;
        width: 100%;

        @media #{$mq-mobile} {
            margin-top: 150px;
        }

        &__img {
            height: 398px;
            left: 0;
            position: absolute;
            top: -110px;
            width: 502px;

            @media #{$mq-tablet} {
                top: -92px;
            }
        }

        &__retangulo {
            background-color: $azul;
            border-radius: 80px;
            float: right;
            height: 215px;
            padding: 40px 55px;
            text-align: right;
            width: 78%;
            @include _flex(flex-end, center);

            @media #{$mq-tablet} {
                height: 270px;
            }

            @media #{$mq-mobile} {
                height: auto;
                margin-top: 220px;
                padding-right: 30px;
                padding-left: 30px;
                text-align: left;
                width: 100%;

                justify-content: flex-start;
            }

            p {
                color: $branco;
                font-family: $bebas-bold;
                font-size: 25px;
                letter-spacing: 1px;
                text-transform: uppercase;
                width: 84%;

                @media #{$mq-tablet} {
                    width: 71%;
                }

                @media #{$mq-mobile} {
                    font-size: 30px;
                    margin-top: 65px;
                    width: 100%;
                }
            }
        }
    }

    .error_button {
        background-color: $amarelo;
        border-radius: 20px;
        color: $azul;
        cursor: pointer;
        display: table;
        font-family: $bebas-bold;
        font-size: 20px;
        height: 50px;
        left: 50%;
        letter-spacing: 2px;
        margin: 40px 0 80px -175px;
        position: relative;
        text-transform: uppercase;
        width: 350px;

        @media #{$mq-mobile} {
            font-size: 28px;
            height: 60px;
            left: 0;
            margin-left: 20px;
            width: 560px;
        }

        &:hover {
            background-color: $azul-escuro;
            color: $branco;
        }
    }
